import {
  post,
  get
} from '@/service/base';
import axios from '@/service/base';
import {
  alertMessage
} from '@/assets/js/tool';
import {
  getLocalStorage
} from '@/assets/js/storage';

/*错误提示*/
function alertError(res) {
  //res为undefined表示未请求到服务器 会在响应拦截里报错 这里只报服务器返回结果后status不为0的错误
  if (res === undefined) return;
  let lang = getLocalStorage('lang') || 'en';
  let text = lang === 'en' ? 'Failed to request data！' : '请求失败！';
  let errText = res ? res.msg || text : text;
  alertMessage('error', errText);
};

/*获取参数*/
function getResData(res, key = 'data') {
  if (res && res.status === 0) {
    return res[key];
  } else {
    alertError(res);
    return null;
  }
};

//获取对象 验证返回接口
function verificationRes(res) {
  if (res) {
    if (res.status === 0) {
      return {
        status: 0
      };
    } else {
      let lang = getLocalStorage('lang') || 'en';
      let text = lang === 'en' ? 'Operation failed！' : '操作失败！';
      let errText = res ? res.msg || text : text;
      alertMessage('error', errText);
      return {
        status: -1
      };
    };
  } else {
    return {
      status: -1
    };
  }
};

/**获取UI模板分类列表*/
export function getUiTypeList() {
  return post('/website/ui/type/list').then(res => {
    let item = {
      id: 'All',
      enName: 'All Type',
      zhName: '全部分类'
    };
    let list = getResData(res) || [];
    list.unshift(item)
    return list;
  }, (err) => {
    alertError(err);
    return [];
  });
};

/**获取驱动包分类列表*/
export function getDriverTypeList() {
  return post('/website/driver/type/list').then(res => {
    let item = {
      id: 'All',
      enName: 'All Type',
      zhName: '全部分类'
    };
    let list = getResData(res) || [];
    list.unshift(item)
    return list;
  }, (err) => {
    alertError(err);
    return [];
  });
};

/**获取驱动 驱动包 UI模板列表*/
export function getShareList(params) {
  return post('/website/list', params).then(res => {
    let list = getResData(res, 'rows') || [];
    return list;;
  }, (err) => {
    alertError(err);
    return [];
  });
};

/**获取所有版本信息*/
export function getVersionList() {
  return post('/website/ide/version/list').then(res => {
    let data = getResData(res, 'data') || [];
    return data;
  }, (err) => {
    alertError(err);
    return [];
  })
};

/**获取我的资源数据*/
export function getMineData() {
  return post('/website/mine').then(res => {
    let data = getResData(res, 'data') || null;
    return data;
  }, (err) => {
    alertError(err);
    return null;
  })
};

//获取ui模板图片地址
export function getUiThumbUrl(id) {
  return post('website/ui/thumb/url', {
    id: id
  }).then((res) => {
    let data = getResData(res, 'data') || '';
    return data;
  }, (err) => {
    alertError(err);
    return '';
  });
};

//撤回
export function shareCancel(type = 0, id) {
  let url = '';
  if (type === 0) url = '/website/driver/cancel';
  if (type === 1) url = '/website/package/cancel';
  if (type === 2) url = '/website/ui/cancel';
  return post(url, {
    id
  }).then((res) => {
    return verificationRes(res);
  }, (err) => {
    alertError(err);
    return {};
  });
};

//修改
export function shareUpdate(type = 0, params) {
  let url = '';
  if (type === 0) url = '/website/driver/update';
  if (type === 1) url = '/website/package/update';
  if (type === 2) url = '/website/ui/update';

  return post(url, params).then((res) => {
    return verificationRes(res);
  }, (err) => {
    alertError(err);
    return {};
  });
}

//删除- 驱动 驱动包 UI模板
export function shareDelete(type = 0, id) {
  let url = '';
  if (type === 0) url = '/website/driver/delete';
  if (type === 1) url = '/website/package/delete';
  if (type === 2) url = '/website/ui/delete';

  return post(url, {
    id
  }).then((res) => {
    return verificationRes(res);
  }, (err) => {
    alertError(err);
    return {};
  });
};

//获取下载地址
export function getResourceDownloadUrl(type = 0, id) {
  return post('website/download/url', {
    type,
    id,
  }).then((res) => {
    let data = getResData(res, 'data') || '';
    return data;
  }, (err) => {
    alertError(err);
    return '';
  });
};

//通知接口下载UI预览数据
export function driverPreviewUI(id, type) {
  let driverUrl = 'website/driver/preview';
  let packageUrl = 'website/package/preview';
  let url = type == 0 ? driverUrl : packageUrl;
  return post(url, {
    id: id
  }).then((res) => {
    return res;
  }, (err) => {
    return {
      status: -1
    };
  });
};

//驱动带UI预览数据
export function getPreviewUI(id, type) {
  let baseURL = (axios.defaults.baseURL).replace('/authorization-web', '');
  let driverUrl = `${baseURL}/data/driverpreview/${id}/ui/data.json`;
  let packageUrl = `${baseURL}/data/packagepreview/${id}/ui/data.json`;
  let url = type == 0 ? driverUrl : packageUrl;
  return get(url).then((res) => {
    return res;
  }, (err) => {
    return null;
  });
};