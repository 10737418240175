import {
  post
} from '@/service/base';

/**修改授权信息*/
export function updateAuthorization(data) {
  return post('/ide/license/renew', data);
};

/**授权码列表*/
export function getAuthCodeList(data) {
  return post('/auth/list', data);
};

/**创建授权码*/
export function createAuthCode(data) {
  return post('/auth/save', data);
};