import {
  post
} from './base';

export * from './request/remote';
export * from './request/resource';
export * from './request/authorization';
export * from './request/support';

/**登录*/
export function login(data) {
  return post('/ide/login', data);
};

/**退出*/
export function logout() {
  return post('/logout');
};

/**获取下载列表*/
export function getDownloadList() {
  return post('/download/list');
};

/**获取下载地址*/
export function getDownloadUrl(data) {
  return post('/download/url', data);
};

/**获取固件列表*/
export function getProductList() {
  return post('/download/list/product');
};

/**ide用户注册-工程师*/
export function registerIde(data) {
  return post('/account/register/ide', data);
};

/**app用户注册-用户*/
export function registerApp(data) {
  return post('/account/register/app', data);
};

/**获取账号列表*/
export function getAccountList() {
  return post('/account/list', {
    S_createTime: 'desc'
  });
};

/**修改工程师账号密码*/
export function changeIdePwd(data) {
  return post('/account/edit/ide', data);
};

/**发送验证码邮件*/
export function sendCodeEmail(data) {
  return post('/account/reset/email', data);
};

/**重置密码*/
export function resetPassword(data) {
  return post('/account/reset/save', data);
};

/**获取用户信息*/
export function getUserInfo() {
  return post('/ide/license/info');
}