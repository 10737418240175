import {
  post
} from '@/service/base';

/**获取技术文档类型*/
export function getSupportType() {
  return post('/support/type/list');
};

/**获取技术列表*/
export function getSupportList(data) {
  return post('/support/doc/list', data);
}

/**获取下载地址*/
export function getSupportDownloadUrl(data) {
  return post('/support/download/url', data);
}