import {
  post
} from '@/service/base';

/**获取远程设备统计信息*/
export function getSummary() {
  return post('/remote/summary');
};

/**获取远程设备列表*/
export function getRemoteList() {
  return post('/download/device/list');
};